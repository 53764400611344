import React, { useState, useEffect } from "react";
import "./DashboardHome.scss";
import magnify from "../../../assests/svg/Magnifier.svg";
import rightArrow from "../../../assests/png/Arrow_Right_MD.png";
import { useAuth } from "../../../contexts/AuthContext";
import { fetchData } from "../../../config/service";
import ApiConfig from "../../../config/ApiConfig";
import { ReactComponent as Left } from "../../../assests/svg/chevron_left.svg";
import { ReactComponent as Right } from "../../../assests/svg/chevron_right.svg";
import { useNavigate } from "react-router-dom";
import Spinner from "../../loader/Spinner";
import SuccessPopup from "../../popup/SuccessPopup";

const Home = () => {
  const { user, token, logout, setBrandID } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [userData, setUserData] = useState({});
  const [number, setNumber] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSelectChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleChange = (event) => {
    setNumber(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading1(false);
    const response = await fetchData(
      ApiConfig.updateSetting,
      setLoading1,
      "post",
      {
        "key_name": "trial_period",
        "parent_key": "brand",
        "key_value": number
      },
      { authorization: `Bearer ${token}` }
    );
    if (response.statusCode === 401 || response.statusCode === 500) {
      logout();
    }
    if (response.status === 200) {
        if(!loading1){
          setShowPopup(true);
        }
        setShowPopup(true);
    }
    // setLoading1(true);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 100);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      let apiLink = "";
      if (debouncedSearchTerm !== "" && selectedState !== "") {
        apiLink = `${ApiConfig.brands}?page=1&limit=500&search=${debouncedSearchTerm}&status=${selectedState}`;
      } else if (debouncedSearchTerm === "" && selectedState !== "") {
        apiLink = `${ApiConfig.brands}?page=1&limit=500&status=${selectedState}`;
      } else if (debouncedSearchTerm !== "" && selectedState === "") {
        apiLink = `${ApiConfig.brands}?page=1&limit=500&search=${debouncedSearchTerm}`;
      } else {
        apiLink = `${ApiConfig.brands}?page=1&limit=500`;
      }
      const response = await fetchData(
        apiLink,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.statusCode === 401 || response.statusCode === 500) {
        logout();
      }
      if (response.status === 200) {
        setUserData(response.data.data.rows);
      }

      const info = await fetchData(
        `${ApiConfig.appSetting}?key_name=trial_period&parent_key=brand`,
        setLoading,
        "get",
        {},
        {}        
      );
      if (info.statusCode === 401 || info.statusCode === 500) {
        logout();
      }
      if (info.status === 200) {
        setNumber(info.data?.data[0]?.key_value ?? 0);
      }
      setLoading(true);
    };
    getEnvDetails();
  }, [debouncedSearchTerm, selectedState, token, logout]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems;
  let totalPages;
  if (loading) {
    currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);
    totalPages = Math.ceil(userData.length / itemsPerPage);
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleClick = (data) => {
    setBrandID(data);
    navigate(`${data}`);
  };

  return (
    <div className="dashboard-home">
      <div className="heading">
        <h2 className="dashboard-heading">
          Hi{" "}
          {user.indexOf(" ") !== -1 ? user.slice(0, user.indexOf(" ")) : user},
        </h2>
        <p className="dashboard-sub-heading">Welcome to your dashboard!</p>
      </div>
      <div className="container">
        <div className="head-container">
          <h2>Set Demo Period (in Days) :</h2>
          <form onSubmit={handleSubmit}>
              <input
                type="number"
                value={number}
                pattern= "[0-9]"
                onChange={handleChange}
                min="0"
              />
            <button className="button2" type="submit">Save</button>
          </form>
        </div>
        <div className="header-tab">
          <div className="input-box">
            <input
              className="search-box"
              placeholder="Search Brand...."
              value={searchTerm}
              onChange={handleInputChange}
            />
            <img className="magnifier" src={magnify} alt="magnifierimage" />
          </div>
          <div className="search-box">
            <select
              name="state"
              id="current_state"
              value={selectedState}
              onChange={handleSelectChange}
            >
              <option value="">All</option>
              <option value="In_Progress">In Progress</option>
              <option value="Trail">Trial</option>
              <option value="Paid">Paid</option>
              <option value="Expired">Expired</option>
            </select>
          </div>
        </div>
        <div className="brand-table">
          {!loading ? (
            <div className="spinner-container">
              <Spinner />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    <p>Brand ID</p>
                  </th>
                  <th>
                    <p>Brand Name</p>
                  </th>
                  <th>
                    <p>Brand Owner</p>
                  </th>
                  <th>
                    <p>Brand Status</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <p>{item.brand_id}</p>
                      </td>
                      <td>
                        <p>{item.company}</p>
                      </td>
                      <td>
                        <p>{item.brand_email}</p>
                      </td>
                      <td>
                        <p>{item.brand_env_selection.selection_status}</p>{" "}
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClick(item.brand_id)}
                        >
                          <img src={rightArrow} alt="rightarrow" />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <Left />
            </button>
            {[...Array(totalPages).keys()].map((number) => (
              <button
                key={number + 1}
                onClick={() => handlePageChange(number + 1)}
                className={currentPage === number + 1 ? "active" : ""}
              >
                {number + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <Right />
            </button>
          </div>
        </div>
      </div>
      {showPopup && <SuccessPopup message="Date saved successfully!" onClose={() => setShowPopup(false)} />}
    </div>
  );
};

export default Home;
