import React, { useState, useEffect } from "react";
import "./DashboardLogin.scss";
import Credential from "../../credential/Credential";
import Transaction from "../../transaction/Transaction";
import Payments from "../../payments/Payments";
import Analytic from "../../analytic/Analytic";
import { useAuth } from "../../../contexts/AuthContext";
import { fetchData } from "../../../config/service";
import ApiConfig from "../../../config/ApiConfig";
import Spinner from "../../loader/Spinner";
import { useNavigate } from "react-router-dom";

const DashboardLogin = () => {
  const { token, brandID, logout ,setBrandID} = useAuth();
  const [isloading, setLoading] = useState(true);
  const [isloading1, setLoading1] = useState(true);
  const [isloading2, setLoading2] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [isLoginEnabled, setIsLoginEnabled] = useState(true);
  const [isTrialRetained, setIsTrialRetained] = useState(true);
  const [brandDetail, setBrandDetail] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [credentialData, setCredential] = useState({});
  const nav = useNavigate();

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.brands}?page=1&limit=10&brand_id=${brandID}`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.statusCode === 401 || response.statusCode === 500) {
        logout();
      }
      if (response.status === 200) {
        setBrandDetail(response.data.data.rows[0]);
        setCredential(response.data.data.rows[0].brand_platform);
        setPaymentData(response.data.data.rows[0].brand_env_selection);
        if (response.data.data.rows[0].regn_status === 0) {
          setIsLoginEnabled(false);
        }
        if (response.data.data.rows[0].brand_env_selection.retail_trail === 0) {
          setIsTrialRetained(false);
        }
      }
      setLoading(false);
    };
    if (brandID === null) {
      const parts = window.location.href.split('/');
      setBrandID(parts[parts.length - 1]);
    } else {
      getEnvDetails();
    }
  }, [brandID, token, logout, nav]);

  const handleLoginToggle = async () => {
    setIsLoginEnabled((prevState) => !prevState);
    setLoading1(true);
    const response = await fetchData(
      `${ApiConfig.enableLogin}/${brandID}`,
      setLoading1,
      "post",
      {
        regn_status: !isLoginEnabled,
      },
      { authorization: `Bearer ${token}` }
    );
    if (response.statusCode === 401 || response.statusCode === 500) {
      logout();
    }

    setLoading1(false);
  };

  const handleTrialRetainToggle = async () => {
    setIsTrialRetained((prevState) => !prevState);
    setLoading1(true);
    const response = await fetchData(
      `${ApiConfig.retainTrail}/${brandID}`,
      setLoading1,
      "post",
      {
        retain_trail: !isTrialRetained,
      },
      { authorization: `Bearer ${token}` }
    );
    if (response.statusCode === 401 || response.statusCode === 500) {
      logout();
    }

    setLoading1(false);

    console.log(isloading1 + "" + isloading);
  };

  const handleLogin = async () => {
    setLoading2(true);
    const response = await fetchData(
      `${ApiConfig.login}/${brandID}`,
      setLoading2,
      "post",
      {},
      { authorization: `Bearer ${token}` }
    );
    if (response?.status === 401 || response?.status === 500) {
      logout();
    } else if (response?.status === 200) {
      // window.location.href = `https://appdev.onewayx.com/login/${response.data.data}`;
      window.open(`${process.env.REACT_APP_BASE_URL}/login/${response.data.data}`, '_blank');
    }
    // console.log(response);
    // setLoading2(false);
  };

  return (
    <>
      {isloading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "30vw",
          }}
        >
          {" "}
          <Spinner />
        </div>
      ) : (
        <div className="dashboad-login">
          <div className="section-1">
            <h2 className="brand-title">
              {brandDetail.company}
            </h2>
            <div className="controls">
              <div className="main-control">
                <div className="status-current">
                  <p>
                    Status : {brandDetail.brand_env_selection.selection_status}
                  </p>
                </div>
                <div className="enable-login">
                  <p>Enable Login :</p>
                  <div className="toggle-container">
                    <input
                      type="checkbox"
                      checked={isLoginEnabled}
                      onChange={() => handleLoginToggle()}
                    />
                    <span className="slider round "></span>
                  </div>
                </div>
                <div className="retain-trial">
                  <p>Retain Trial :</p>
                  <div className="toggle-container">
                    <input
                      type="checkbox"
                      checked={isTrialRetained}
                      onChange={() => handleTrialRetainToggle()}
                    />
                    <span className="slider round "></span>
                  </div>
                </div>
              </div>
              <div className="login">
                {isloading2 ? (
                  <div className="spinner-container" style={{width:'80px', height:'80px'}}>
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <button className="button2" onClick={() => handleLogin()}>
                      Login
                    </button>
                    <span>Login to Brand’s Dashboard</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="section-2">
            <div>
              <button
                type="button"
                onClick={() => setCurrentTab(1)}
                className={currentTab === 1 ? "active" : ""}
              >
                Payments
              </button>
              <button
                type="button"
                onClick={() => setCurrentTab(2)}
                className={currentTab === 2 ? "active" : ""}
              >
                Credentials
              </button>
              <button
                type="button"
                onClick={() => setCurrentTab(3)}
                className={currentTab === 3 ? "active" : ""}
              >
                Analytics
              </button>
              <button
                type="button"
                onClick={() => setCurrentTab(4)}
                className={currentTab === 4 ? "active" : ""}
              >
                Transactions
              </button>
            </div>
          </div>
          <div>
            {currentTab === 1 ? (
              <Payments payment={paymentData} />
            ) : currentTab === 2 ? (
              <Credential credential={credentialData} />
            ) : currentTab === 3 ? (
              <Analytic />
            ) : (
              <Transaction />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardLogin;
